import { defineMessages } from 'react-intl';

const messages = defineMessages({
	formHeader: {
		id: 'login.loginForm.header',
		description: 'Form header text',
		defaultMessage: 'Sign in to your Planview account',
	},
	email: {
		id: 'login.loginForm.email',
		description: 'email field placeholder',
		defaultMessage: 'Email',
	},
	editEmail: {
		id: 'login.loginPasswordForm.editEmail',
		description: 'Edit email icon tooltip text',
		defaultMessage: 'Edit email address',
	},
	editOrganization: {
		id: 'login.loginPasswordForm.editOrg',
		description: 'Edit organization icon tooltip text',
		defaultMessage: 'Edit organization',
	},
	organization: {
		id: 'login.loginForm.organization',
		description:
			'organization field placeholder to choose which business unit the user wants to login to',
		defaultMessage: 'Organization',
	},
	continue: {
		id: 'login.loginForm.continue',
		description: 'continue button text',
		defaultMessage: 'Continue',
	},
	password: {
		id: 'login.loginPasswordForm.password',
		description: 'password field placeholder',
		defaultMessage: 'Password',
	},
	passwordVisible: {
		id: 'login.loginPasswordForm.passwordVisible',
		description: 'Password visible review icon tooltip text',
		defaultMessage: 'Password visible',
	},
	captchaLabel: {
		id: 'login.loginForm.captchaLabel',
		description: 'Label for CAPTCHA value',
		defaultMessage: 'CAPTCHA',
	},
	captchaPlaceholder: {
		id: 'login.loginPasswordForm.captchaPlaceholder',
		description: 'Field placeholder for CAPTCHA value',
		defaultMessage: 'Please type in the CAPTCHA value from above',
	},
	captchaAlt: {
		id: 'login.loginPasswordForm.captchaAlt',
		description: 'alt text for CAPTCHA image',
		defaultMessage: 'CAPTCHA image',
	},
	signIn: {
		id: 'login.loginPasswordForm.signIn',
		description: 'Sign in button text',
		defaultMessage: 'Sign in',
	},
	forgotPassword: {
		id: 'login.loginPasswordForm.forgotPassword',
		description: 'Link text to navigate to forgot password form',
		defaultMessage: 'I forgot my password',
	},
});

export default messages;
