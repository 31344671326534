import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
	Badge,
	DESTRUCTIVE,
	Dropdown,
	DropdownListItem,
	GENERIC,
	HorizontalTab,
	HorizontalTabGroup,
	Modal,
	MODAL_LARGE,
} from '@planview/pv-uikit';
import AppLogo from '../../../components/common/appLogo/AppLogo';
import {
	ButtonEmpty,
	ButtonGhost,
} from '../../../components/common/button/Button';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import MappedUsersGrid from '../mappedUsers/MappedUsersGrid';
import UnmappedUsersGrid from '../unmappedUsers/UnmappedUsersGrid';
import { AppContext, AppContextProps, UserContext } from '../../../context';
import { HBox, VBox } from '../../../components/common/Layout';
import Details from '../mappingDetails/Details';
import messages from './ProductDetailsPage.messages';
import { PAGE_LIMIT } from '../../../components/common/grid/Grid';
import { DotsVertical } from '@planview/pv-icons';
import TileErrorMessage from '../../../components/home/productTile/TileErrorMessage';
import ProductUsersGrid from './ProductUsersGrid';
import LicenseInfo from '../../common/license/LicenseInfo';
import { align, theme, sizePx, spacingPx, text } from '@planview/pv-utilities';
import { Application, ApplicationName, Tenant } from '../../../types';
import { LicenseDetail } from '../../../types/api/licenses';
import { SetUsePlanviewIdResponse } from '../../../types/api/tenants';
import { updateApplications } from '../../../helpers/util';
import {
	CautionLabel,
	LeftAlignedWarningIcon,
	WarningDiv,
} from '../../../components/common/Dialog';
import { ModalType } from '@planview/pv-uikit/es/modal/types';

HorizontalTab.displayName = 'Tab';

const ProductInfoSection = styled.div`
	background-color: ${theme.textInverse};
`;

const TenantInfoContainer = styled.div`
	padding-top: ${spacingPx.small};
	padding-left: ${spacingPx.small};
`;

const LogoAndSyncContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${spacingPx.xlarge};
`;

const TenantInfo = styled.div`
	${text.regular};
	color: ${theme.textSecondary};
	padding-top: ${spacingPx.xsmall};
	padding-bottom: ${spacingPx.xsmall};
`;

const ActivateButton = styled(ButtonGhost)`
	align-self: flex-end;
	margin-right: 15px;
	margin-top: 15px;
`;

const DeactivateTenant = styled.div`
	${align.centerV}
	height: ${sizePx.small};
	align-self: flex-end;
	color: ${theme.textSecondary};
	${text.regular};
	margin-top: auto;
	margin-right: ${spacingPx.small};
`;

const StyledTabGroup = styled(HorizontalTabGroup)`
	background-color: ${theme.textInverse};
`;

const StyledBadge = styled(Badge)`
	display: inline-block;
	height: 15px;
	margin-left: ${spacingPx.xsmall};
`;

const StyledSSOButtonContainer = styled.div`
	display: flex;
	flex-direction: row-reverse;
`;

type HandleActivateTenantClickProps = {
	intl: IntlShape;
	activationState: boolean;
	appContext: AppContextProps;
	app: Application;
};

const handleActivateTenantClick = async (
	props: HandleActivateTenantClickProps,
) => {
	const { intl, activationState, appContext, app } = props;

	const infoMessage = activationState
		? messages.activateSuccess
		: messages.deactivateSuccess;

	const requestData = {
		state: activationState,
	};

	const { success, state } =
		await requestWithErrorHandling<SetUsePlanviewIdResponse>({
			method: 'put',
			url: `/io/v1/tenant/${app.envSelectorEncodedString}/usePlanviewId`,
			dataObj: requestData,
			appContext,
			intl,
			infoMessage,
		});

	if (success) {
		updateApplications(appContext, {
			...app,
			ssoEnabled: state,
		});
	}
};

type ActivateProductConfirmationModalProps = {
	app: Application;
	onConfirm: () => void;
	onCancel: () => void;
};

/**
 * Confirmation modal for activating or deactivating a product in Planview Admin
 */
const ActivateProductConfirmationModal = (
	props: ActivateProductConfirmationModalProps,
) => {
	const { app, onConfirm, onCancel } = props;
	const intl = useIntl();

	let header = '';
	let message = '';
	let confirmText = '';
	let modalType: ModalType = GENERIC;

	if (app.ssoEnabled) {
		header = intl.formatMessage(messages.deactivateProductHeader);
		message = intl.formatMessage(messages.deactivateProductWarning);
		confirmText = intl.formatMessage(messages.disableButton);
		modalType = DESTRUCTIVE;
	} else {
		header = intl.formatMessage(messages.activateProductHeader);
		message = intl.formatMessage(messages.activateProductWarning);
		confirmText = intl.formatMessage(messages.enableButton);
	}

	return (
		<Modal
			type={modalType}
			headerText={header}
			confirmText={confirmText}
			cancelText={intl.formatMessage(messages.cancelButton)}
			size={MODAL_LARGE}
			onConfirm={onConfirm}
			onCancel={onCancel}
		>
			<WarningDiv>
				<CautionLabel>
					<LeftAlignedWarningIcon />
					{intl.formatMessage(messages.cautionText)}
				</CautionLabel>
			</WarningDiv>
			<div>{message}</div>
		</Modal>
	);
};

const UnmappedUsersTabLabel = ({ count }: { count: number }) => {
	const label = count > PAGE_LIMIT ? `${PAGE_LIMIT}+` : count;
	return (
		<>
			<FormattedMessage {...messages.unmappedUsersTab} />
			<StyledBadge>{label}</StyledBadge>
		</>
	);
};

const TAB_USERS = 'mapped';
const TAB_UNMAPPED_USERS = 'unmapped';
const TAB_DETAILS = 'details';

type ProductDetailsPageParams = {
	application: ApplicationName;
	tenantId: string;
};

const ProductDetailsPage = () => {
	const { application, tenantId } = useParams<ProductDetailsPageParams>();
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);

	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState(TAB_USERS);
	const [unmappedCount, setUnmappedCount] = useState(0);
	const [tenant, setTenant] = useState<Tenant>();
	const [licenseDetails, setLicenseDetails] = useState<LicenseDetail[]>([]);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const { applications } = appContext;
	const { customer } = userContext;

	const app = applications.find(
		({ envSelector }) =>
			envSelector.application === application &&
			envSelector.tenantId === tenantId,
	);

	if (!app) {
		throw Error(`App not found: ${application}~${tenantId}`);
	}

	const { showPlanviewAdminSSO, foundationApp } = app;
	const showProductGrid =
		foundationApp || customer.topDownUserManagementEnabled;

	useEffect(() => {
		async function getProductDetailsInfo() {
			if (!app) {
				return;
			}

			const { success, ...rest } = await requestWithErrorHandling({
				method: 'get',
				url: `/io/v1/tenant/${app.envSelectorEncodedString}`,
				appContext,
				intl,
			});

			const tenant = rest as Tenant;
			if (success) {
				setUnmappedCount(tenant.unmappedUsersCount || 0);
				setTenant(tenant);
			}

			setLoading(false);
		}

		void getProductDetailsInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const confirmationModal = showConfirmationModal ? (
		<ActivateProductConfirmationModal
			app={app}
			onConfirm={() => {
				if (app?.ssoEnabled) {
					void handleActivateTenantClick({
						intl,
						activationState: false,
						appContext,
						app,
					});
					setShowConfirmationModal(false);
				} else {
					void handleActivateTenantClick({
						intl,
						activationState: true,
						appContext,
						app,
					});
					setShowConfirmationModal(false);
				}
			}}
			onCancel={() => setShowConfirmationModal(false)}
		/>
	) : null;

	const SSOActivationButton = (props: {
		app: Application;
		loading: boolean;
	}) => {
		const { app, loading } = props;
		const ssoActivatedMsg = intl.formatMessage(
			messages.planviewAdminActivated,
		);
		return (
			<StyledSSOButtonContainer>
				{app.ssoEnabled ? (
					<DeactivateTenant>
						{userContext.customer.topDownUserManagementEnabled ? (
							ssoActivatedMsg
								.concat(' - ')
								.concat(
									intl.formatMessage(messages.tdumEnabled),
								)
						) : (
							<>
								{ssoActivatedMsg}
								<Dropdown
									alignRight={true}
									trigger={(active, _, triggerProps) => (
										<ButtonEmpty
											message={undefined}
											activated={active}
											icon={<DotsVertical />}
											{...triggerProps}
										/>
									)}
								>
									<DropdownListItem
										text={intl.formatMessage(
											messages.deactivatePlanviewAdmin,
										)}
										onActivate={() =>
											setShowConfirmationModal(true)
										}
									/>
								</Dropdown>
							</>
						)}
					</DeactivateTenant>
				) : (
					<ActivateButton
						message={messages.activateTenantButton}
						loading={loading}
						onClick={() => setShowConfirmationModal(true)}
					/>
				)}
				{confirmationModal}
			</StyledSSOButtonContainer>
		);
	};

	const onRefresh = ({ count }: { count: number }) => {
		setUnmappedCount(count);
	};

	// Determine if result of getProductDetailsInfo has returned
	if (!tenant?.application) {
		return null;
	}

	return (
		<React.Fragment>
			<ProductInfoSection>
				<HBox>
					<VBox>
						<TenantInfoContainer>
							<LogoAndSyncContainer>
								<AppLogo app={application} />
								<TileErrorMessage
									tenant={tenant}
									useIcon={true}
								/>
							</LogoAndSyncContainer>
							<TenantInfo>
								{app.domain} | {app.title}
							</TenantInfo>
						</TenantInfoContainer>
					</VBox>
					<VBox>
						{showPlanviewAdminSSO ? (
							<SSOActivationButton loading={loading} app={app} />
						) : null}
						<LicenseInfo
							envSelector={app.envSelector}
							licenseTypes={tenant.licenseTypes}
							licenseDetails={licenseDetails}
							setLicenseDetails={setLicenseDetails}
						/>
					</VBox>
				</HBox>
			</ProductInfoSection>

			{showProductGrid ? (
				<ProductUsersGrid
					/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
					/* @ts-ignore */
					updateAppDetails={setTenant}
					appDetails={{
						...app,
						...tenant,
					}}
					setLicenseDetails={setLicenseDetails}
				/>
			) : (
				<>
					<StyledTabGroup defaultIndex={0}>
						<HorizontalTab
							label={intl.formatMessage(messages.usersTab)}
							onActivate={() => setActiveTab(TAB_USERS)}
						/>
						<HorizontalTab
							/* TODO: Tabs only accept strings, but we'll keep logic as-is for now */
							/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
							/* @ts-ignore */
							label={
								<UnmappedUsersTabLabel count={unmappedCount} />
							}
							onActivate={() => setActiveTab(TAB_UNMAPPED_USERS)}
						/>
						{app.showUserSync ? (
							<HorizontalTab
								label={intl.formatMessage(messages.detailsTab)}
								onActivate={() => setActiveTab(TAB_DETAILS)}
							/>
						) : (
							<></>
						)}
					</StyledTabGroup>
					{activeTab === TAB_USERS ? (
						<MappedUsersGrid
							/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
							/* @ts-ignore */
							updateAppDetails={setTenant}
							appDetails={{
								...app,
								...tenant,
							}}
						/>
					) : null}
					{activeTab === TAB_UNMAPPED_USERS ? (
						/* app DOES exist, but we're not typing users grid */
						/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
						/* @ts-ignore */
						<UnmappedUsersGrid app={app} onRefresh={onRefresh} />
					) : null}
					{activeTab === TAB_DETAILS ? <Details app={app} /> : null}
				</>
			)}
		</React.Fragment>
	);
};

export default ProductDetailsPage;
