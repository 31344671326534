import React, { useContext, useState } from 'react';
import { DESTRUCTIVE, Modal, MODAL_MEDIUM, Radio } from '@planview/pv-uikit';
import styled from 'styled-components';
import { IntlShape, useIntl } from 'react-intl';
import { Combobox, Form, RadioGroup } from '@planview/pv-form';
import { ApplicationName, Customer, Tenant } from '../../../../types';
import { AppContext, AppContextProps } from '../../../../context';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import { theme, spacingPx, text } from '@planview/pv-utilities';
import { Warning } from '@planview/pv-icons';
import { DeleteApplicationDataDto } from '../../../../types/api/customers';

const manageDataSupportedApplications = new Set([
	'E1_PRM',
	'LEANKIT',
	'ROADMAPS',
]);
const okrsApplications = new Set(['E1_PRM', 'LEANKIT']);

enum ManageDataAction {
	cleanup = 'cleanup',
	delete = 'delete',
}

type ModalState = {
	tenantGroupId: string | null;
	product: TenantDataProductDetail | null;
	products: TenantDataProductDetail[];
	action: ManageDataAction | null;
	showModal: boolean;
	showDoubleConfirmation: boolean;
	customer: Customer;
	isSending: boolean;
};

type ModalStatePartialUpdate = {
	tenantGroupId?: string | null;
	product?: TenantDataProductDetail | null;
	products?: TenantDataProductDetail[];
	action?: ManageDataAction | null;
	showModal?: boolean;
	showDoubleConfirmation?: boolean;
	customer?: Customer;
	isSending?: boolean;
};

type TenantDataProductDetail = {
	application: ApplicationName;
	label: string;
	value: string;
	tenantId: string | null;
};

type TenantDataProduct = {
	[key: string]: TenantDataProductDetail;
};

type TenantData = {
	[key: string]: {
		label: string;
		value: string;
		products: TenantDataProduct;
	};
};

const getInitialModalState = (customer: Customer): ModalState => {
	return {
		tenantGroupId: null,
		product: null,
		products: [],
		action: null,
		showModal: false,
		showDoubleConfirmation: false,
		customer,
		isSending: false,
	};
};

const ExplanationText = styled.div`
	${text.regular};
`;
const RadioExplanationText = styled.div`
	${text.regular};
	margin-left: ${spacingPx.large};
`;
const ExplanationWarningContainer = styled.div`
	color: ${theme.iconWarning};
`;
const ExplanationCautionContainer = styled.div`
	display: flex;
`;
const ExplanationCaution = styled.span`
	${text.semibold};
	padding-top: ${spacingPx.small};
`;
const WarningIcon = styled(Warning)`
	height: auto;
	min-height: unset;
	padding-top: ${spacingPx.small};
	padding-right: ${spacingPx.xsmall};
	width: fit-content;
`;

const ExplanationWarning = ({ warning }: { warning?: string | null }) => {
	return (
		<ExplanationWarningContainer>
			<ExplanationCautionContainer>
				<WarningIcon />
				<ExplanationCaution>Caution</ExplanationCaution>
			</ExplanationCautionContainer>
			<div>
				{warning || 'This is destructive and data cannot be recovered.'}
			</div>
		</ExplanationWarningContainer>
	);
};

const makeRequest = async ({
	intl,
	appContext,
	modalState,
}: {
	intl: IntlShape;
	appContext: AppContextProps;
	modalState: ModalState;
}) => {
	const { action, customer, product, tenantGroupId } = modalState;
	if (!action || !product || !tenantGroupId) {
		throw new Error(
			`Required data not set: ${JSON.stringify({
				action,
				product,
				tenantGroupId,
			})}`,
		);
	}

	const actionStr = ManageDataAction[action].toString().toUpperCase();
	const url = `/io/v1/admin/customer/${customer.id}/data/${product.application}`;

	const dto: DeleteApplicationDataDto = {
		action: actionStr,
		tenantId: product.tenantId,
		tenantGroupId,
	};

	const { success } = await requestWithErrorHandling({
		method: 'delete',
		url,
		dataObj: { data: dto },
		appContext,
		intl,
	});

	return success;
};

// produce a data structure matching the dialog lookup requirements, e.g.:
// {
//   tenantGroupId: {
//      products: {
//        OKRS: { ... }
//        ROADMAPS: { ... }
// }}}
const getProductData = (tenants: Tenant[]) => {
	return tenants.reduce((tenantData: TenantData, tenant) => {
		const {
			application,
			tenantGroupId,
			sandbox: isSandbox,
			productName,
			tenantId,
		} = tenant;

		if (!manageDataSupportedApplications.has(application)) {
			return tenantData;
		}

		// add top-level tenant group property if it doesn't already exist
		if (!tenantData[tenantGroupId]) {
			const tenantGroupLabel = `${
				isSandbox ? 'Sandbox' : 'Production'
			} (${tenantGroupId})`;

			tenantData[tenantGroupId] = {
				products: {},
				label: tenantGroupLabel,
				value: tenantGroupId,
			};
		}

		let product: TenantDataProductDetail;

		// build product object
		const isOkrs = okrsApplications.has(application);
		if (isOkrs) {
			product = {
				application: 'OKRS',
				label: 'OKRs',
				value: 'OKRS',
				tenantId: null,
			};
		} else {
			// is roadmaps
			product = {
				application,
				label: productName,
				value: tenantId,
				tenantId,
			};
		}

		// add product property (overwrites if multiple OKRs tenants)
		const products: TenantDataProduct = {
			...tenantData[tenantGroupId].products,
		};
		products[product.application] = product;

		// (re-)add products for the tenant group
		tenantData[tenantGroupId] = {
			...tenantData[tenantGroupId],
			products,
		};

		return tenantData;
	}, {});
};

const DoubleConfirmationModal = ({
	modalState,
	setModalState,
}: {
	modalState: ModalState;
	setModalState: (state: ModalStatePartialUpdate) => void;
}) => {
	const appContext = useContext(AppContext);
	const intl = useIntl();

	return (
		<Modal
			type={DESTRUCTIVE}
			size={MODAL_MEDIUM}
			headerText="Are you sure?"
			confirmText="Delete"
			disableConfirm={modalState.isSending}
			cancelText="Cancel"
			onConfirm={async () => {
				setModalState({ isSending: true });
				const success = await makeRequest({
					intl,
					appContext,
					modalState,
				});
				setModalState(
					success
						? getInitialModalState(modalState.customer)
						: { isSending: false, showDoubleConfirmation: false },
				);
			}}
			onCancel={() => setModalState({ showDoubleConfirmation: false })}
		>
			The{' '}
			{modalState.action === ManageDataAction.delete
				? 'Delete'
				: 'Cleanup'}{' '}
			action you are about to take is an unrecoverable, destructive
			action. Confirm all options are correct and that you would like to
			proceed.
		</Modal>
	);
};

const ManageDataModal = ({
	modalState,
	setModalState,
}: {
	modalState: ModalState;
	setModalState: (state: ModalStatePartialUpdate) => void;
}) => {
	const doubleConfirmationModal = DoubleConfirmationModal({
		modalState,
		setModalState,
	});
	const productData = getProductData(modalState.customer.tenants || []);
	const tenantGroupItems = Object.values(productData);
	const {
		customer,
		tenantGroupId,
		product,
		products,
		action,
		showDoubleConfirmation,
	} = modalState;

	return (
		<Modal
			size={MODAL_MEDIUM}
			headerText="Manage data"
			confirmText="Continue"
			cancelText="Cancel"
			onConfirm={() => setModalState({ showDoubleConfirmation: true })}
			onCancel={() => setModalState(getInitialModalState(customer))}
			disableConfirm={!tenantGroupId || !product || !action}
		>
			<Form label="Manage data form">
				<Combobox
					label={{ text: 'Select tenant group', required: true }}
					options={tenantGroupItems}
					clearable={false}
					onChange={(option) => {
						if (typeof option?.value === 'string') {
							setModalState({
								tenantGroupId: option.value,
								product: null,
								products: Object.values(
									productData[option.value].products,
								),
								action: null,
							});
						}
					}}
				/>
				<ExplanationText>
					By selecting a tenant group, only data for that group will
					be managed.
				</ExplanationText>
				<p />
				<Combobox
					label={{ text: 'Select product', required: true }}
					options={products.map(({ value, label }) => ({
						value,
						label,
					}))}
					value={product}
					disabled={tenantGroupId === null}
					clearable={false}
					onChange={(option) => {
						setModalState({
							product: products.find(
								(product) => product.value === option?.value,
							),
							action: null,
						});
					}}
				/>
				<ExplanationText>
					By selecting a product, you are choosing to manage data for
					that product.
					<ExplanationWarning
						warning={
							'This is a destructive action that cannot be reversed.'
						}
					/>
				</ExplanationText>
				<p />
				<RadioGroup
					label={{ text: 'Select an action', required: true }}
					onChange={(val) =>
						setModalState({ action: val as ManageDataAction })
					}
					value={action}
				>
					<Radio
						label="Cleanup data"
						value={ManageDataAction.cleanup}
						disabled={
							product === null ||
							product.application === 'ROADMAPS'
						}
					/>
					<RadioExplanationText>
						A cleanup action will crawl all data and remove any
						unassociated (orphaned) data related to this product
						throughout the tenant group.
						<ExplanationWarning />
					</RadioExplanationText>
					<Radio
						label="Delete ALL data"
						value={ManageDataAction.delete}
						disabled={product === null}
					/>
					<RadioExplanationText>
						A Delete action will crawl all data and remove ANY data
						related to this product throughout the tenant group,
						resetting it to a blank state.
						<ExplanationWarning />
					</RadioExplanationText>
				</RadioGroup>
			</Form>

			{showDoubleConfirmation && doubleConfirmationModal}
		</Modal>
	);
};

const useManageDataAction = (customer: Customer) => {
	const [modalState, setModalState] = useState<ModalState>(
		getInitialModalState(customer),
	);

	const setModalStateImpl = (state: ModalStatePartialUpdate) =>
		setModalState({ ...modalState, ...state });

	const manageDataEnabled = (customer.tenants || []).some((tenant) =>
		manageDataSupportedApplications.has(tenant.application),
	);

	const manageDataAction = () => setModalStateImpl({ showModal: true });

	const manageDataModal = modalState.showModal && (
		<ManageDataModal
			key="manage-data-modal"
			modalState={modalState}
			setModalState={setModalStateImpl}
		/>
	);
	return {
		manageDataEnabled,
		manageDataAction,
		manageDataModal,
	};
};

export default useManageDataAction;
