import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Combobox } from '@planview/pv-form';
import messages from './NewOrganizationDialog.messages';
import styled from 'styled-components';
import { theme, spacingPx, text } from '@planview/pv-utilities';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { GridResultDto } from '../../../types/api/customers';
import { AppContext } from '../../../context';
import {
	CautionLabel,
	LeftAlignedWarningIcon,
	option,
	SectionHeader,
} from '../../../components/common/Dialog';
import { UseFormReturnType } from '@mantine/form';

const WarningDiv = styled.div`
	color: ${theme.iconWarning};
	padding-bottom: ${spacingPx.medium};
	width: 460px;
	${text.regular};
`;

type OrgHierarchyForm = UseFormReturnType<
	{
		orgStructure: { label: string; value: string };
		orgParent: { label: string; value: string };
	},
	(values: {
		orgStructure: { label: string; value: string };
		orgParent: { label: string; value: string };
	}) => {
		orgStructure: { label: string; value: string };
		orgParent: { label: string; value: string };
	}
>;

type OrgHierarchySectionProps = {
	orgId?: string;
	orgForm: OrgHierarchyForm;
	parentCustomerId?: string;
	parentCustomerTitle?: string;
	showOrgHierarchyComponents?: boolean;
};

export const OrgHierarchySection = ({
	orgId,
	orgForm,
	parentCustomerId,
	parentCustomerTitle,
}: OrgHierarchySectionProps) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const { orgStructure, orgParent } = orgForm.values;

	const [orgParentOptions, setOrgParentOptions] = useState<option[]>([]);

	const orgStructureStandaloneOption = React.useMemo(() => {
		return {
			label: intl.formatMessage(
				messages.orgHierarchyOrgStructureStandaloneOption,
			),
			value: 'Standalone',
		};
	}, [intl]);
	const orgStructureChildOption = React.useMemo(() => {
		return {
			label: intl.formatMessage(
				messages.orgHierarchyOrgStructureChildOption,
			),
			value: 'Child',
		};
	}, [intl]);

	useEffect(() => {
		const getParentOrgList = async () => {
			const { results } = await requestWithErrorHandling<GridResultDto>({
				method: 'get',
				url: `/io/v1/admin/customer/parent`,
				intl,
				appContext,
			});
			const options: option[] = [];

			results?.forEach((parentCustomerDto) => {
				options.push({
					label: parentCustomerDto.title,
					value: parentCustomerDto.id,
				});
			});
			setOrgParentOptions(options);
		};
		if (parentCustomerId) {
			orgForm.setValues({ orgStructure: orgStructureChildOption });
			orgForm.setValues({
				orgParent: {
					label: parentCustomerTitle || '',
					value: parentCustomerId,
				},
			});
		} else {
			if (orgId) {
				orgForm.setValues({
					orgStructure: orgStructureStandaloneOption,
				});
			}
			void getParentOrgList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentCustomerId]);

	return (
		<SectionHeader>
			<Combobox
				{...orgForm.getInputProps('orgStructure')}
				label={intl.formatMessage(
					messages.orgHierarchyOrgStructureHeader,
				)}
				options={[
					orgStructureStandaloneOption,
					orgStructureChildOption,
				]}
				onChange={(nextState) => {
					orgForm.setValues({ orgStructure: nextState as option });
					if (nextState?.value === 'Standalone') {
						orgForm.setValues({
							orgParent: { label: '', value: '' },
						});
					}
				}}
				disabled={!!parentCustomerId}
				clearable={false}
				withAsterisk={true}
				data-lpignore={true}
			/>

			{orgStructure.value === 'Child' ? (
				<SectionHeader>
					{(!parentCustomerId && !orgId) ||
					(parentCustomerId && orgId) ? null : (
						<WarningDiv>
							<CautionLabel>
								<LeftAlignedWarningIcon />
								<FormattedMessage {...messages.cautionText} />
							</CautionLabel>
							<div>
								<FormattedMessage
									{...messages.orgHierarchyOrgStructureWarning}
								/>
							</div>
						</WarningDiv>
					)}
					<Combobox
						{...orgForm.getInputProps('orgParent')}
						value={orgParent.value === '' ? null : orgParent}
						label={intl.formatMessage(
							messages.orgHierarchyParentHeader,
						)}
						placeholder={intl.formatMessage(
							messages.orgHierarchyParentBoxLabel,
						)}
						options={orgParentOptions}
						disabled={!!parentCustomerId}
						clearable={false}
						creatable={true}
						withAsterisk={true}
					/>

					{parentCustomerId ? null : (
						<WarningDiv>
							<CautionLabel>
								<LeftAlignedWarningIcon />
								<FormattedMessage {...messages.cautionText} />
							</CautionLabel>
							<div>
								<FormattedMessage
									{...messages.orgHierarchyParentWarning}
								/>
							</div>
						</WarningDiv>
					)}
				</SectionHeader>
			) : null}
		</SectionHeader>
	);
};
