import { defineMessages } from 'react-intl';

const messages = defineMessages({
	ssoHeader: {
		id: 'admin.settings.ssoHeader',
		description: 'Header for SSO configuration in Settings tab',
		defaultMessage: 'Single sign-on configuration',
	},
	configurationHeader: {
		id: 'admin.settings.configurationHeader',
		description: 'Header text for sso settings',
		defaultMessage: 'Configuration',
	},
	itConfigurationSubHeader: {
		id: 'admin.settings.itConfigurationSubHeader',
		description: 'Subheader text for it configuration section',
		defaultMessage: 'IT configuration',
	},
	inputConfigurationSubHeader: {
		id: 'admin.settings.inputConfigurationSubHeader',
		description: 'Subheader text for input configuration section',
		defaultMessage: 'Input configuration',
	},
	inputConfigurationSubHeaderTooltip: {
		id: 'admin.settings.inputConfigurationSubHeaderTooltip',
		description: 'Tooltip for input configuration section',
		defaultMessage:
			'Enter either the metadata URL or XML configuration from your IT department below.',
	},
	advancedConfigurationSubHeader: {
		id: 'admin.settings.advancedConfigurationSubHeader',
		description: 'Subheader text for advanced configuration section',
		defaultMessage: 'Advanced configuration',
	},
	helpTooltipAdvancedConfiguration: {
		id: 'admin.settings.helpTooltipadvancedConfiguration',
		description: 'Tooltip for help for Advanced Configuration section',
		defaultMessage:
			'Additional configuration options to customize your SSO process.',
	},
	configurationDetailsColumn: {
		id: 'admin.settings.configurationDetailsColumn',
		description: 'Column label for configuration details in the grid',
		defaultMessage: 'Configuration details',
	},
	linkValueColumn: {
		id: 'admin.settings.linkValueColumn',
		description: 'Column label for link / value in the grid',
		defaultMessage: 'Link / Value',
	},
	documentationLabel: {
		id: 'admin.settings.documentationLabel',
		description: 'Label/value for documentation URL',
		defaultMessage: 'Documentation',
	},
	spMetadataUrlLabel: {
		id: 'admin.settings.spMetadataUrlLabel',
		description:
			'Label/value for SP Metadata URL (generated for the customer after saving the advanced config)',
		defaultMessage: 'SP metadata',
	},
	samlEntityIdLabel: {
		id: 'admin.settings.samlEntityIdLabel',
		description:
			'Label for a SAML Entity ID, which is the URL configured on an IDP to identify Planview Admin',
		defaultMessage: 'SAML identifier (Entity ID)',
	},
	samlAcsUrlLabel: {
		id: 'admin.settings.samlAcsUrlLabel',
		description:
			'Label for a SAML Assertion Consumer Servic URL, which is the URL configured on an IDP to send a browser to Planview Admin',
		defaultMessage: 'SSO / Assertion consumer service',
	},
	regionLabel: {
		id: 'admin.settings.regionLabel',
		description: 'Label/value for region',
		defaultMessage: 'Region',
	},
	nameIdFormatLabel: {
		id: 'admin.settings.nameIdFormatLabel',
		description: 'Label/value for name id format',
		defaultMessage: 'Name ID format',
	},
	testSignInUrlLabel: {
		id: 'admin.settings.testSignInUrlLabel',
		description: 'Label/value for where to test the sign in',
		defaultMessage: 'URL for sign-in testing',
	},
	samlMetadataUrlLabel: {
		id: 'admin.settings.urlLabel',
		description: 'Label for SAML metadata URL radio option',
		defaultMessage: 'Enter the URL to download SAML metadata',
	},
	helpTooltipSamlMetadataUrl: {
		id: 'admin.settings.helpTooltipSamlMetadataUrl',
		description: 'Tooltip for help for SAML metadata URL',
		defaultMessage:
			'Paste your metadata URL here. This URL comes from your SSO provider and provides your SAML XML configuration to Planview Admin.',
	},
	samlMetadataUrlPlaceholder: {
		id: 'admin.settings.urlPlaceholder',
		description: 'Placeholder for SAML metadata URL',
		defaultMessage: 'Metadata URL',
	},
	refreshMetadata: {
		id: 'admin.settings.refreshMetadata',
		description: 'Button text to refresh metadata',
		defaultMessage: 'Refresh metadata',
	},
	samlMetadataXmlLabel: {
		id: 'admin.settings.xmlLabel',
		description: 'Label for SAML metadata XML radio option',
		defaultMessage: 'Enter SAML metadata XML manually',
	},
	helpTooltipSamlMetadataXml: {
		id: 'admin.settings.helpTooltipSamlMetadataXml',
		description: 'Tooltip for help for SAML metadata XML',
		defaultMessage:
			'Paste your SAML metadata XML configuration here. This metadata comes from your IT department.',
	},
	samlMetadataXmlPlaceholder: {
		id: 'admin.settings.xmlPlaceholder',
		description: 'Placeholder for SAML metadata XML',
		defaultMessage: 'Metadata XML',
	},
	enableSSO: {
		id: 'admin.settings.enableSSO',
		description: 'Label for Enable Single Sign On switch',
		defaultMessage: 'Enable SSO for all',
	},
	noteItem1: {
		id: 'admin.settings.noteItem1',
		description:
			'Note text before save operation in the SSO configuration section',
		defaultMessage:
			'With this configuration, the metadata XML will be downloaded from the URL and used to connect to the IDP.',
	},
	noteItem2: {
		id: 'admin.settings.noteItem2',
		description:
			'Informational message before save operation in the SSO configuration section (Line 2)',
		defaultMessage:
			'The metadata will then be revalidated every 24 hours or click the "Refresh metadata" button to refresh now.',
	},
	save: {
		id: 'admin.settings.save',
		description:
			'Save button text in the Details tab -> SSO Configuration section',
		defaultMessage: 'Save',
	},
	cancel: {
		id: 'admin.settings.cancel',
		description:
			'Cancel button text in the Details tab -> SSO Configuration section',
		defaultMessage: 'Cancel',
	},
	yesButton: {
		id: 'admin.settings.confirmation.yes',
		description: 'Button text for Yes',
		defaultMessage: 'Yes',
	},
	yesButtonWhileSaving: {
		id: 'admin.settings.confirmation.yesButtonWhileSaving',
		description: 'Button text after clicking yes and save is occurring',
		defaultMessage: 'Saving...',
	},
	saveHeader: {
		id: 'admin.settings.confirmation.header',
		description: 'Header text for save config confirmation dialog',
		defaultMessage: 'Save settings?',
	},
	saveSettingsNoSsoChange: {
		id: 'admin.settings.confirmation.saveSettings',
		description:
			'Body text placeholder (not currently used) for when Enable SSO switch is not changed',
		defaultMessage: 'This will save all settings.',
	},
	enableContent: {
		id: 'admin.settings.confirmation.enableContent',
		description: 'Content for enable SSO config confirmation dialog',
		defaultMessage:
			'This will enable Single Sign On for your account. Only users with password required will be able to sign in to Planview Admin with a password.',
	},
	disableContent: {
		id: 'admin.settings.confirmation.disableContent',
		description: 'Content for disable SSO config confirmation dialog',
		defaultMessage:
			'This will disable Single Sign On for your account. Users will only be able to sign in to Planview Admin with a password.',
	},
	continueText: {
		id: 'admin.settings.confirmation.continueText',
		description: 'Text for disable SSO config confirmation dialog',
		defaultMessage: 'Are you sure you want to continue?',
	},
	noSelectionError: {
		id: 'admin.settings.save.error.noSelection',
		description:
			'Text for the error message when there is no option selected',
		defaultMessage: 'Please select an option before saving.',
	},
	noAttributeValueError: {
		id: 'admin.settings.save.error.attribute.noValue',
		description:
			'Text for the error message when there is no value specified for additional attributes',
		defaultMessage:
			"'Name' is a required field for additional SAML attributes. Please enter a value before saving.",
	},
	samlHelpText: {
		id: 'admin.settings.samlHelpText',
		description: 'Text/link for help configuring SSO',
		defaultMessage: 'Setting Up SSO in the Customer Success Center',
	},
	ssoConfigHeaderTooltip: {
		id: 'admin.settings.ssoConfigHeaderTooltip',
		description: 'Text for sso configuration header tooltip',
		defaultMessage:
			'This is a self-service page allowing you to configure your single sign-on configuration.',
	},
	ssoITTooltip: {
		id: 'admin.settings.ssoITTooltip',
		description: 'Text for IT confiration section tooltip',
		defaultMessage:
			'You can use the buttons to the right to either copy the information in the table or mail it directly to your IT department.',
	},
	copyAllButton: {
		id: 'admin.settings.copyAllButton',
		description: 'Text for copy all button',
		defaultMessage: 'Copy all',
	},
	mailToButton: {
		id: 'admin.settings.mailToButton',
		description: 'Text for mail to button',
		defaultMessage: 'Mail to link',
	},
	testSSOButton: {
		id: 'admin.settings.testSSOButton',
		description: 'Button text to test SSO in a new browser popup',
		defaultMessage: 'Test',
	},
	testSSOSubheader: {
		id: 'admin.settings.testSSOSubheader',
		description: 'Section text for testing SSO',
		defaultMessage: 'Test sign-in',
	},
	configurationInstructions: {
		id: 'admin.settings.configurationInstructions',
		description: 'Configuration instructions',
		defaultMessage:
			'To configure single-sign on (SSO) for Planview Admin, complete each section of this page.',
	},
	configurationInstructionsLine1: {
		id: 'admin.settings.configurationInstructionsLine1',
		description: 'Configuration instructions',
		defaultMessage:
			'In IT Configuration, either copy the information from the table or mail it directly to your IT Team. Your IT Team will use that information to add Planview Admin to your SSO provider.',
	},
	configurationInstructionsLine2: {
		id: 'admin.settings.configurationInstructionsLine2',
		description: 'Configuration instructions',
		defaultMessage:
			'In Input Configuration, enter either a URL or XML metadata from your SSO provider to complete the configuration.',
	},
	configurationInstructionsLine3: {
		id: 'admin.settings.configurationInstructionsLine3',
		description: 'Configuration instructions',
		defaultMessage:
			'In Advanced Configuration, you can enable other options or add additional attributes to customize your SSO process.',
	},
	copyToClipboardSuccess: {
		id: 'admin.settings.copyToClipboardSuccess',
		description:
			'Success message after copying IT configuration to clipboard',
		defaultMessage: 'SSO information copied to clipboard',
	},
	copyToClipboardError: {
		id: 'admin.settings.copyToClipboardError',
		description:
			'Error message trying to copy IT configuration to clipboard',
		defaultMessage: 'Could not copy SSO information to clipboard',
	},
	itConfigurationEmailSubject: {
		id: 'admin.settings.itConfigurationEmailSubject',
		description: 'Subject text for it configuration email',
		defaultMessage: 'Planview Admin IT configuration',
	},
	itConfigurationEmailInstructions: {
		id: 'admin.settings.itConfigurationEmailInstructions',
		description: 'Body text for IT configuration email',
		defaultMessage:
			"Please use the below information to add Planview Admin to your organization's IdP.",
	},
	metadataExpirationLabel: {
		id: 'admin.settings.MetadataExpirationLabel',
		description: 'Label text for metadata expiration',
		defaultMessage: 'Expiration: {expTime}',
	},
	metadataExpiredLabel: {
		id: 'admin.settings.metadataExpiredLabel',
		description: 'Label text for metadata expired',
		defaultMessage: 'Expired: {expTime}',
	},
	testSignInTooltip: {
		id: 'admin.settings.testSignInTooltip',
		description: 'Text for test sign in tooltip',
		defaultMessage:
			'Click Test to test your configuration before enabling it for all users.',
	},
	toggleErrorMessage: {
		id: 'admin.settings.toggleErrorMessage',
		description: 'Warning toast text when toggling SSO with empty metadata',
		defaultMessage:
			'Enter either a valid Metadata URL or valid Metadata XML to enable SSO.',
	},
	toggleWarning: {
		id: 'admin.settings.toggleWarning',
		description: 'Warning toast message when toggling with unsaved changes',
		defaultMessage: 'Please save SSO configuration first',
	},
});

export default messages;
