import { FormattedMessage, useIntl } from 'react-intl';
import messages from './NewOrganizationDialog.messages';
import { InputNumeric, Label } from '@planview/pv-form';
import { Switch } from '@planview/pv-uikit';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import { theme, spacingPx, text } from '@planview/pv-utilities';
import AppLogo from '../../../components/common/appLogo/AppLogo';
import { default as licenseMessages } from '../../common/license/LicenseInfo.messages';
import { ApplicationName } from '../../../types';
import {
	LicenseDetailExpanded,
	LicenseType,
} from '../../../types/api/licenses';
import { isLicensingSupported } from '../../../helpers/util';
import { AppContext } from '../../../context';

const LicensesContainer = styled.div`
	padding: ${spacingPx.small} 0 ${spacingPx.small} 0;
`;

const LicensesHeaderContainer = styled.div`
	${text.title};
	padding-bottom: ${spacingPx.small};
`;

const LicensesBodyContainer = styled.div`
	padding: 0 0 ${spacingPx.medium} 0;
	gap: ${spacingPx.small};
	width: 460px;
	${text.regular};
`;

const LicensesTableContainer = styled.div`
	display: flex;
	padding-bottom: ${spacingPx.medium};
`;

const AppLogoContainer = styled.div`
	width: 100px;
	align-self: center;
`;

const StyledAppLogo = styled(AppLogo)`
	margin: 15px 15px 0 15px;
	padding: 3px 0 1px 0;
	align-self: start;
`;

const LicensesInputContainer = styled.div`
	padding-left: 124px;
	width: 200px;
`;

const ErrorMessage = styled.span`
	color: ${theme.error500};
	${text.regular};
`;

type TenantLicenseInfo = {
	tenantId: string | null;
	licenseTypes: LicenseType[];
};

type LicenseSectionProps = {
	licenseDetailUpdates: LicenseDetailExpanded[];
	setLicenseDetailUpdates: Dispatch<SetStateAction<LicenseDetailExpanded[]>>;
	totalLicensesByApp: Map<ApplicationName, TenantLicenseInfo>;
	ideaplaceChecked: boolean;
	setIdeaplaceChecked: Dispatch<SetStateAction<boolean>>;
	setLicenseChangesMade: Dispatch<SetStateAction<boolean>>;
	errorMessage: string;
};

export const LicensesSection = ({
	licenseDetailUpdates,
	setLicenseDetailUpdates,
	totalLicensesByApp,
	ideaplaceChecked,
	setIdeaplaceChecked,
	setLicenseChangesMade,
	errorMessage,
}: LicenseSectionProps) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const { featureFlags } = appContext;

	const updateTotalLicenses = (
		app: ApplicationName,
		licenseType: LicenseType,
		newCount: number,
	) => {
		const newLicenseDetailUpdates: LicenseDetailExpanded[] = [
			...licenseDetailUpdates,
		];
		const targetLicenseDetailIndex = newLicenseDetailUpdates.findIndex(
			(licenseDetail) => licenseDetail.licenseName === licenseType.name,
		);

		if (targetLicenseDetailIndex >= 0) {
			newLicenseDetailUpdates[targetLicenseDetailIndex].totalLicenses =
				newCount;
			setLicenseDetailUpdates(newLicenseDetailUpdates);
		} else {
			const newLicenseDetail: LicenseDetailExpanded = {
				application: app,
				tenantId: null,
				licenseName: licenseType.name,
				totalLicenses: newCount,
			};
			newLicenseDetailUpdates.push(newLicenseDetail);
			setLicenseDetailUpdates(newLicenseDetailUpdates);
		}
	};

	const getLicensePlaceholder = (licenseType: LicenseType) => {
		const licenseDetail = licenseDetailUpdates.find(
			(licenseDetail) => licenseDetail.licenseName === licenseType.name,
		);
		return licenseDetail ? licenseDetail.totalLicenses.toString() : '0';
	};

	return (
		<LicensesContainer>
			<LicensesHeaderContainer>
				<FormattedMessage {...messages.productLicensesHeader} />
			</LicensesHeaderContainer>
			<LicensesBodyContainer>
				<FormattedMessage {...messages.productLicensesDescription} />
			</LicensesBodyContainer>
			{[...totalLicensesByApp.keys()].map(
				(application) =>
					isLicensingSupported(application, featureFlags) && (
						<LicensesTableContainer
							key={`${application}-licenses-table-container`}
						>
							<AppLogoContainer>
								<StyledAppLogo app={application} scale={0.14} />
							</AppLogoContainer>

							{totalLicensesByApp
								.get(application)
								?.licenseTypes.map((licenseType) =>
									!licenseType.unlimited ? (
										<LicensesInputContainer
											key={`${application}-${licenseType.name}-license-input-container`.toLowerCase()}
										>
											<Label>
												<FormattedMessage
													{...licenseMessages.licenseTypeHeader}
													values={{
														LicenseType:
															licenseType.displayText,
													}}
												/>
											</Label>
											<InputNumeric
												id={`${licenseType.name}-licenses-input`.toLowerCase()}
												icon={null}
												placeholder={getLicensePlaceholder(
													licenseType,
												)}
												onChange={(value: string) => {
													updateTotalLicenses(
														application,
														licenseType,
														+value,
													);
													setLicenseChangesMade(true);
												}}
												selected
											/>
											{errorMessage ? (
												<ErrorMessage>
													{errorMessage}
												</ErrorMessage>
											) : null}
										</LicensesInputContainer>
									) : (
										application === 'TEAMTAP' &&
										licenseType.name ===
											'ideaplace-teamtap' && (
											<LicensesInputContainer
												key={`${application}-${licenseType.name}-license-input-container`.toLowerCase()}
											>
												<Label>
													<FormattedMessage
														{...licenseMessages.licenseTypeHeader}
														values={{
															LicenseType:
																licenseType.displayText,
														}}
													/>
												</Label>
												<Switch
													id={`${application}-${licenseType.name}-licenses-toggle`.toLowerCase()}
													label={
														ideaplaceChecked
															? intl.formatMessage(
																	messages.productEnabledLabel,
																)
															: intl.formatMessage(
																	messages.productDisabledLabel,
																)
													}
													checked={ideaplaceChecked}
													onChange={() => {
														setIdeaplaceChecked(
															!ideaplaceChecked,
														);
														updateTotalLicenses(
															application,
															licenseType,
															+0,
														);
														setLicenseChangesMade(
															true,
														);
													}}
												/>
											</LicensesInputContainer>
										)
									),
								)}
						</LicensesTableContainer>
					),
			)}
		</LicensesContainer>
	);
};
