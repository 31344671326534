import React, { useContext, useRef, useState } from 'react';
import {
	FormattedDate,
	FormattedMessage,
	injectIntl,
	useIntl,
} from 'react-intl';
import styled from 'styled-components';
import Grid from '../../../components/common/grid/Grid';
import { GridSearchInput } from '../../../components/common/input/GridSearchInput';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import ActionsMenu from './ActionsMenu';
import messages from './MappedUsersGrid.messages';
import { HBox } from '../../../components/common/Layout';
import { Toolbar } from '../../../components/common/toolbar/Toolbar';
import { Cancel } from '@planview/pv-icons';
import { useLocalStoragePreferences } from '@planview/pv-grid';
import { AppContext } from '../../../context';
import { theme, spacingPx, sizePx, text } from '@planview/pv-utilities';
import {
	ToolbarButtonPrimary,
	ToolbarSection,
	ToolbarSectionLeft,
} from '@planview/pv-toolbar';
import ToolbarActionsMenu from '../actionsMenu/ToolbarActionsMenu';
import { ConfirmationModal } from '../../../components/common/modal/Modal';

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	overflow: hidden;
	background-color: ${theme.backgroundNeutral0};
`;

const LastSynced = styled(HBox)`
	margin-left: ${spacingPx.small};
	margin-right: ${spacingPx.small};
	color: ${theme.textSecondary};
	${text.regular};
`;

const ToolbarButtonWrapper = styled(ToolbarButtonPrimary)`
	min-width: ${sizePx.large};
	${text.regular};
`;

const dateCellRenderer = ({ value }) =>
	value ? <FormattedDate value={new Date(value)} /> : '';

const getUsersGridColumns = (intl, app, appContext) => {
	const { formatMessage } = intl;
	const { roles, shouldShowAddedOnColumn } = app;
	const columns = [
		{
			id: 'firstName',
			label: formatMessage(messages.firstNameColumn),
			width: 300,
		},
		{
			id: 'lastName',
			label: formatMessage(messages.lastNameColumn),
			width: 300,
		},
		{
			id: 'email',
			label: formatMessage(messages.emailColumn),
			width: 300,
		},
	];

	if (shouldShowAddedOnColumn) {
		columns.push({
			id: 'addedOn',
			label: formatMessage(messages.addedOnColumn),
			cell: {
				label: (data) => dateCellRenderer(data),
			},
			width: 100,
		});
	}

	if (appContext.featureFlags.showLastLogin && app.ssoEnabled) {
		columns.push({
			id: 'lastLoginAt',
			label: intl.formatMessage(messages.lastLoginAtColumn),
			// todo mike 2023-11-2 make this sortable: true once we have UserTenantMappingItem's in
			//   single table and the necessary indices in place
			sortable: false,
			width: 80,
			cell: {
				label: (data) => dateCellRenderer(data),
			},
		});
	}

	if (roles && roles.length > 0) {
		const rolesById = roles.reduce(
			(acc, role) => acc.set(role.id, role),
			new Map(),
		);

		columns.push({
			id: 'role',
			label: formatMessage(messages.roleColumn),
			sortable: false,
			width: 100,
			cell: {
				label: ({ value }) => {
					let string = '';
					if (value) {
						const role = rolesById.get(value);
						if (role) {
							string = role.displayText;
						}
					}
					return string;
				},
			},
		});
	}

	return columns;
};

const isSyncInProgress = (appDetails) => {
	return !!appDetails.syncStart;
};

export const LastSyncedInfo = ({ appDetails, loading }) => {
	const { formatDate } = useIntl();
	const { showUserSync, lastSync, syncCount = 0 } = appDetails;
	if (!showUserSync) {
		return null;
	}

	const syncDate = lastSync
		? formatDate(lastSync, {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			})
		: '';
	let message = lastSync ? messages.lastSynced : messages.lastSyncedNever;
	if (isSyncInProgress(appDetails)) {
		message = messages.lastSyncedInProgress;
	}

	return loading ? null : (
		<LastSynced data-testid="last-sync-info">
			<div>
				<FormattedMessage
					{...message}
					values={{
						syncDate,
						syncCount,
					}}
				/>
			</div>
		</LastSynced>
	);
};

const MappedUsersGrid = ({ ...props }) => {
	const { appDetails, intl } = props;
	const grid = useRef();
	const appContext = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const columns = getUsersGridColumns(intl, appDetails, appContext);
	const [selectedMappings, setSelectedMappings] = useState([]);
	const [showRemoveMappingsModal, setShowRemoveMappingsModal] =
		useState(false);
	const [removalInProgress, setRemovalInProgress] = useState(false);
	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	const toolbarActions = [
		{
			message: messages.removeMapping,
			icon: <Cancel />,
			isEnabled: (mappings) => mappings.length,
			activateFn: (mappings) => {
				setSelectedMappings(mappings);
				setShowRemoveMappingsModal(true);
			},
		},
	];

	const handleRemoveMappings = async () => {
		setRemovalInProgress(true);
		const { appDetails } = props;
		const { envSelectorEncodedString } = appDetails;
		const userIds = selectedMappings.map((m) => m.id);
		await requestWithErrorHandling({
			method: 'post',
			url: `/io/v1/user/unmap/${envSelectorEncodedString}`,
			dataObj: { userIds },
			appContext,
			intl,
			infoMessage: messages.removeUserMappingSuccess,
		}).then(() => setRemovalInProgress(false));
	};

	const handleSyncUsers = async () => {
		const { appDetails } = props;
		setLoading(true);
		await requestWithErrorHandling({
			method: 'post',
			url: `/io/v1/user/sync/${appDetails.envSelectorEncodedString}`,
			appContext: appContext,
			intl: intl,
			infoMessage: messages.tenantSyncInfo,
		});
		setLoading(false);
	};

	const handleRefreshStatus = async () => {
		const { appDetails } = props;
		setLoading(true);
		const { success, ...rest } = await requestWithErrorHandling({
			method: 'get',
			url: `/io/v1/tenant/${appDetails.envSelectorEncodedString}`,
			appContext: appContext,
			intl: intl,
		});
		setLoading(false);
		if (success) {
			const { syncCount } = rest;
			// Update the app details so SSO status and Unmapped user count can be updated
			props.updateAppDetails(rest);
			// Refresh the grid only if the syncCount has changed.
			if (appDetails.syncCount !== syncCount) {
				grid.current.refresh();
			}
		}
	};

	// Wrapper for actions menu so we can pass both app and grid props
	const RowActionsMenu = (props) => (
		<ActionsMenu app={appDetails} {...props} />
	);

	return (
		<>
			<Toolbar data-testid="toolbar">
				<ToolbarSectionLeft>
					<GridSearchInput
						value={searchValue}
						onChange={(searchQuery) => {
							setSearchValue(searchQuery);
						}}
						triggerSearch={(searchQuery) => {
							grid.current.triggerSearch(searchQuery);
						}}
					/>
				</ToolbarSectionLeft>
				<ToolbarSection>
					<LastSyncedInfo {...props} />
					{appDetails.showUserSync ? (
						isSyncInProgress(appDetails) ? (
							<ToolbarButtonWrapper
								size={'small-compact'}
								loading={loading}
								onClick={handleRefreshStatus}
							>
								<FormattedMessage
									{...messages.refreshSyncStatusButton}
								/>
							</ToolbarButtonWrapper>
						) : (
							<ToolbarButtonWrapper
								size={'small-compact'}
								loading={loading}
								onClick={handleSyncUsers}
							>
								<FormattedMessage
									{...messages.syncUsersButton}
								/>
							</ToolbarButtonWrapper>
						)
					) : null}
					<ToolbarActionsMenu
						rowData={selectedMappings}
						actions={toolbarActions}
						message={messages.bulkActionsButton}
						alignRight={true}
						hideDisabled={false}
					/>
				</ToolbarSection>
			</Toolbar>
			<Container>
				<Grid
					columns={columns}
					actionsMenu={RowActionsMenu}
					selectionType="checkbox"
					url={`/io/v1/user/mapped/${appDetails.envSelectorEncodedString}`}
					innerRef={(ref) => {
						grid.current = ref;
					}}
					preferencesAdapter={preferencesAdapter}
					clearFilters={() => {
						setSearchValue('');
						grid.current.resetFiltering();
					}}
					onSelectionChange={(items) => setSelectedMappings(items)}
					{...props}
				/>
			</Container>
			{showRemoveMappingsModal ? (
				<ConfirmationModal
					type={'DESTRUCTIVE'}
					onConfirm={() => {
						handleRemoveMappings().then(() => {
							setShowRemoveMappingsModal(false);
							grid.current.refresh();
						});
					}}
					onCancel={() => setShowRemoveMappingsModal(false)}
					confirmText={intl.formatMessage(messages.yesButton)}
					cancelText={intl.formatMessage(messages.noButton)}
					headerText={intl.formatMessage(messages.removeMappings)}
					message={intl.formatMessage(
						messages.removeMappingsConfirmation,
						{ count: selectedMappings.length },
					)}
					disableConfirm={removalInProgress}
				/>
			) : null}
		</>
	);
};

export default injectIntl(MappedUsersGrid);
