import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Spinner, SIZE_XLARGE } from '@planview/pv-uikit';
import { spacingPx, text } from '@planview/pv-utilities';
import { Section, SectionTitle } from '../../../components/home/Section';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { AppContext } from '../../../context';
import messages from './Details.messages';

const Container = styled.div`
	height: 100%;
	overflow: auto;
	padding: ${spacingPx.small} 24px;
`;

const Content = styled.div`
	${text.regular};
	line-height: ${spacingPx.large};
	margin: 0 ${spacingPx.medium};
`;

const EmptyContent = styled.div`
	padding: ${spacingPx.medium};
`;

const DetailsContent = ({ app }) => {
	const [loading, setLoading] = useState(true);
	const intl = useIntl();
	const { formatDate } = intl;
	const [lastSyncDetails, setLastSyncDetails] = useState();
	const appContext = useContext(AppContext);
	const { checkAppVersion } = appContext;

	useEffect(() => {
		async function getProductDetailsInfo() {
			const { success, lastSyncResult, _appVersion } =
				await requestWithErrorHandling({
					method: 'get',
					url: `/io/v1/tenant/${app.envSelectorEncodedString}/details`,
					appContext,
					intl,
				});

			if (success) {
				setLastSyncDetails(lastSyncResult);
			}
			setLoading(false);
			checkAppVersion(_appVersion);
		}
		getProductDetailsInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const lastSyncMessages = [];
	const lastSyncErrors = [];
	let lastSyncTimestamp = '';
	let key = 1;
	if (lastSyncDetails) {
		lastSyncTimestamp = formatDate(lastSyncDetails.timestamp, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
		});
		for (const syncMessage of lastSyncDetails.messages) {
			lastSyncMessages.push(<li key={key++}>{syncMessage}</li>);
		}
		for (const syncError of lastSyncDetails.errors) {
			lastSyncErrors.push(<li key={key++}>{syncError}</li>);
		}
	}

	return loading ? (
		<Spinner size={SIZE_XLARGE} />
	) : (
		<Content>
			{lastSyncDetails ? (
				<div>
					<div>
						<span>
							<strong>
								<FormattedMessage
									{...messages.timestampLabel}
								/>
							</strong>{' '}
							{lastSyncTimestamp}
						</span>
					</div>
					<div>
						<span>
							<strong>
								<FormattedMessage {...messages.countLabel} />
							</strong>{' '}
							{lastSyncDetails.count}
						</span>
					</div>
					<div>
						<span>
							<strong>
								<FormattedMessage {...messages.messagesLabel} />
							</strong>
						</span>
						<ul>{lastSyncMessages}</ul>
					</div>
					<div>
						<span>
							<strong>
								<FormattedMessage {...messages.errorsLabel} />
							</strong>
						</span>
						<ul>{lastSyncErrors}</ul>
					</div>
				</div>
			) : (
				<EmptyContent>
					<FormattedMessage {...messages.noLastSyncText} />
				</EmptyContent>
			)}
		</Content>
	);
};

const Details = ({ app }) => {
	return (
		<Container>
			<Section>
				<SectionTitle>
					<FormattedMessage {...messages.syncHeader} />
				</SectionTitle>
				<DetailsContent app={app} />
			</Section>
		</Container>
	);
};

export default Details;
