import styled from 'styled-components';
import { NonPhoneMinWidth, VBox } from '../common/Layout';
import { theme, sizePx, spacingPx, text } from '@planview/pv-utilities';

export const Section = styled(VBox)`
	flex-basis: auto;
	line-height: ${sizePx.small};
	@media only screen and (min-width: ${NonPhoneMinWidth}px) {
		margin: ${spacingPx.medium};
	}
`;

export const SectionTitle = styled.div`
	${text.h2};
	background-color: ${theme.gray300};
	line-height: ${sizePx.small};
	padding-left: ${spacingPx.small};
	color: ${theme.textPrimary};
	display: flex;
`;
