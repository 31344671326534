import React, { ReactElement } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import messages from './ActionsMenu.messages';
import { DotsVertical, IconProps } from '@planview/pv-icons';
import { ListItem } from '@planview/pv-uikit';
import { ToolbarDropdownMenu } from '@planview/pv-toolbar';

export interface ToolbarAction<RowType> {
	message: MessageDescriptor;
	activateFn: (rowData: RowType[]) => void;
	isEnabled: (rowData: RowType[]) => boolean;
	isHidden?: (dataData: RowType[]) => boolean;
	icon: ReactElement<IconProps> | undefined;
}

/**
 * The actions for a user, used for Actions button in the toolbar
 *
 * @component
 * @param {Object} props
 * @param {Array/Object} props.rowData		array or single row of data for which actions are to be shown
 * @param {Object[]} props.actions			array of action configurations. Each element must have a message, isEnabled function, and onActivate function
 * @param {boolean} [props.alignRight=true]	align the menu to the right of its trigger, defaults true
 * @param {Object} [props.message=null]		optional message object to show instead of the vertical dots for the trigger
 * @param {boolean} [props.hideDisabled=true] true(default) to hide disabled items. false will show all action items
 * @return {JSX.Element} the actions menu
 */
const ToolbarActionsMenu = <RowType,>({
	rowData,
	actions,
	alignRight = true,
	message = null,
	hideDisabled = true,
}: {
	rowData: RowType | RowType[];
	actions: ToolbarAction<RowType>[];
	alignRight: boolean;
	message: MessageDescriptor | null;
	hideDisabled: boolean;
}): JSX.Element | null => {
	const intl = useIntl();
	const rowDataArray = Array.isArray(rowData) ? rowData : [rowData];

	const trigger =
		message != null
			? { label: intl.formatMessage(message), withCaret: true }
			: {
					tooltip: intl.formatMessage(messages.actionsToolTip),
					'aria-label': intl.formatMessage(messages.rowActionsLabel),
					icon: <DotsVertical />,
				};

	return actions.length ? (
		<>
			<ToolbarDropdownMenu
				alignRight={alignRight}
				trigger={trigger}
				label={message ? intl.formatMessage(message) : ''}
			>
				<>
					{
						actions
							.map((action) => {
								const isEnabled =
									action.isEnabled(rowDataArray);
								const isHiddenOverride =
									action.isHidden &&
									action.isHidden(rowDataArray);
								if (
									(hideDisabled && !isEnabled) ||
									isHiddenOverride
								) {
									return null;
								}

								return (
									<ListItem
										key={action.message.id}
										label={intl.formatMessage(
											action.message,
										)}
										icon={action.icon}
										disabled={!isEnabled}
										onActivate={() =>
											action.activateFn(rowDataArray)
										}
									/>
								);
							})
							.filter((item) => item) // remove nulls
					}
				</>
			</ToolbarDropdownMenu>
		</>
	) : null;
};

ToolbarActionsMenu.displayName = 'ToolbarActionsMenu';
export default ToolbarActionsMenu;
