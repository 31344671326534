import styled from 'styled-components';
import { theme, spacingPx, text } from '@planview/pv-utilities';
import React, { useState } from 'react';
import { ButtonEmpty, MODAL_SMALL, Tooltip } from '@planview/pv-uikit';
import { Modal } from '../../common/modal/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './ProductTile.messages';
import modalMessages from '../../common/modal/Modal.messages';
import { WarningFilled } from '@planview/pv-icons';
import { Tenant } from '../../../types';

const ErrorMessageText = styled.a`
	${text.semibold};
	color ${theme.textError};
	cursor: pointer;
`;

const ErrorInfo = styled.div`
	margin: ${spacingPx.small};
`;

const LeftAlignedWarningFilledIcon = styled(WarningFilled)`
	width: 40px;
	float: left;
`;

export const ErrorMessageModal = ({
	tenant,
	onToggle,
}: {
	tenant: Tenant;
	onToggle: (value: boolean) => void;
}) => {
	const intl = useIntl();
	return (
		<Modal
			id="about-app-modal"
			onConfirm={() => onToggle(false)}
			onCancel={() => onToggle(false)}
			cancelText=""
			confirmText={intl.formatMessage(modalMessages.close)}
			headerText={intl.formatMessage(messages.syncErrors)}
			size={MODAL_SMALL}
		>
			<ErrorInfo>
				<FormattedMessage {...messages.syncErrorsDialogText} />
			</ErrorInfo>
			<ErrorInfo>{tenant.lastSyncErrorMessage}</ErrorInfo>
		</Modal>
	);
};

type TileErrorMessageProps = {
	tenant: Tenant | undefined;
	useIcon: boolean;
};

const TileErrorMessage = ({
	tenant,
	useIcon = false,
}: TileErrorMessageProps) => {
	const [showModal, setShowModal] = useState(false);

	const message = tenant?.lastSyncErrorMessage;
	return (
		<div>
			{message && (
				<Tooltip text={message}>
					{useIcon ? (
						<ButtonEmpty
							icon={
								<LeftAlignedWarningFilledIcon
									color={theme.iconError}
								/>
							}
							onClick={() => setShowModal(true)}
						/>
					) : (
						<ErrorMessageText
							data-testid="tile-error-message"
							onClick={() => setShowModal(true)}
						>
							<FormattedMessage {...messages.syncErrors} />
						</ErrorMessageText>
					)}
				</Tooltip>
			)}
			{showModal && tenant && (
				<ErrorMessageModal tenant={tenant} onToggle={setShowModal} />
			)}
		</div>
	);
};

export default TileErrorMessage;
