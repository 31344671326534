import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedDate, IntlShape, useIntl } from 'react-intl';
import { theme } from '@planview/pv-utilities';
import messages from './OrganizationsPage.messages';
import { Link } from '../../../components/common/Link';
import { Checkmark } from '@planview/pv-icons';
import { Customer } from '../../../types';
import {
	Column,
	Grid,
	GridCellBase,
	GridCellChips,
	GridCellChipsItem,
	GridRowId,
	GridRowMeta,
	useGridRow,
	useLocalStoragePreferences,
} from '@planview/pv-grid';
import { AppContext, OrgTagsContext } from '../../../context';
import { CustomerDto } from '../../../types/api/customers';
import { GridActionsMenu } from '@planview/pv-grid/src/types';
import { EmptyState, EmptyStateFilter } from '@planview/pv-uikit';
import { OrgTag } from '../../../types/api/orgTags';

const tdumCellRenderer = ({ value }: { value: boolean }) =>
	value ? <Checkmark /> : '';

const dateFormatter = ({ value }: { value: string | number | Date }) =>
	value ? (
		<FormattedDate
			value={new Date(value)}
			day="numeric"
			month="numeric"
			year="numeric"
		/>
	) : (
		''
	);

const getColumns = (
	intl: IntlShape,
	topDownUserManagement: boolean,
	enableUpBranchCode: boolean,
	enableCustomerDomain: boolean,
	availableOrgTagsByName: Map<string, OrgTag>,
) => {
	const cols = [
		{
			id: 'title',
			label: intl.formatMessage(messages.orgNameColumn),
			tree: true,
			cell: {
				sortStrategy: 'natural',
				Renderer: ({
					rowId,
					tabIndex,
				}: {
					rowId: GridRowId;
					tabIndex: number;
				}) => {
					const rowData: Customer = useGridRow(rowId);
					const title = rowData?.title;
					if (!title) {
						return null;
					}

					const isParent = Boolean(rowData?.parent);
					return isParent ? (
						<GridCellBase tabIndex={tabIndex}>
							{rowData.title}
						</GridCellBase>
					) : (
						<GridCellBase tabIndex={tabIndex}>
							<Link
								to={`/pvadmin/orgs/${rowData.id}`}
								style={{ color: theme.linkNormal }}
							>
								{rowData.title}
							</Link>
						</GridCellBase>
					);
				},
			},
			width: 300,
		},
		{
			id: 'id',
			label: intl.formatMessage(messages.idColumn),
			width: 300,
		},
		{
			id: 'regulatoryRegion',
			label: intl.formatMessage(messages.regulatoryRegionColumn),
			width: 150,
			cell: {
				value({ row }: { row: Customer }) {
					// Parent orgs do not have a meaningful regulatory region
					return row.parent ? '' : row.regulatoryRegion;
				},
			},
		},
		enableCustomerDomain && {
			id: 'domain',
			label: intl.formatMessage(messages.domainColumn),
			width: 300,
		},
		{
			id: 'orgTags',
			label: intl.formatMessage(messages.orgTagsColumn),
			cell: {
				Renderer({
					rowId,
					tabIndex,
				}: {
					rowId: GridRowId;
					tabIndex: number;
				}) {
					const row: Customer = useGridRow(rowId);
					const value: GridCellChipsItem[] = row.orgTags.map(
						(orgTag) => {
							const key = availableOrgTagsByName.get(orgTag)?.id;
							const label =
								availableOrgTagsByName.get(orgTag)?.label || '';
							return { key, label };
						},
					);
					return <GridCellChips tabIndex={tabIndex} value={value} />;
				},
			},
			width: 300,
		},
		enableUpBranchCode && {
			id: 'uofpBranchCode',
			label: intl.formatMessage(messages.uofpBranchCode),
			width: 300,
		},
		topDownUserManagement && {
			id: 'topDownUserManagementEnabled',
			label: intl.formatMessage(messages.TDUMenabled),
			cell: {
				value({ row }: { row: Customer }) {
					return row.topDownUserManagementEnabled;
				},
				label: (data: { value: boolean }) => tdumCellRenderer(data),
			},
			width: 150,
		},
		{
			id: 'pendingDeletionTime',
			label: intl.formatMessage(messages.scheduledDeletionTime),
			cell: {
				sortStrategy: 'natural',
				value({ row }: { row: Customer }) {
					return row.pendingDeletionTime;
				},
				label: (data: { value: string | number | Date }) =>
					dateFormatter(data),
			},
			width: 300,
		},
	];
	return cols.filter(Boolean);
};

const getTreeRows = (rows: CustomerDto[]) => {
	const idsArray: string[] = [];
	const metaObject = new Map<string, GridRowMeta<CustomerDto>>();
	const data = new Map<string, CustomerDto>();

	rows.forEach((customer) => {
		data.set(customer.id, customer);
		if (customer.parentCustomerId != null) {
			let children = metaObject.get(customer.parentCustomerId)?.children;
			if (children != undefined) {
				children.push(customer.id);
			} else {
				children = [customer.id];
			}

			metaObject.set(customer.parentCustomerId, {
				type: 'tree',
				children: children,
			});
		} else {
			idsArray.push(customer.id);
		}
	});

	return { idsArray, metaObject, data };
};

type OrganizationsGridProps = {
	searchValue: string;
	onClearFilters: () => void;
	actionsMenu: GridActionsMenu;
	loading?: boolean | number;
	rows: CustomerDto[];
};

const OrganizationsGrid = (props: OrganizationsGridProps) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const orgTagsContext = useContext(OrgTagsContext);
	const { rows, searchValue, onClearFilters, actionsMenu, loading } = props;
	const { topDownUserManagement, enableUpBranchCode, enableCustomerDomain } =
		appContext.featureFlags;
	const [expandedRows, setExpandedRows] = useState<Set<string>>(
		new Set<string>(),
	);

	const rowFilter = useMemo(() => {
		const escapedSearchValue = searchValue.replace(
			/[.*+?^${}()|[\]\\]/g,
			'\\$&',
		);
		const searchRegex = new RegExp(escapedSearchValue, 'i');
		return (customer: CustomerDto) =>
			searchRegex.test(customer.title) ||
			searchRegex.test(customer.parentCustomerTitle || '') ||
			searchRegex.test(customer.parentCustomerId || '') ||
			searchRegex.test(customer.uofpBranchCode || '') ||
			searchRegex.test(customer.id);
	}, [searchValue]);

	const treeData = useMemo(() => {
		const { idsArray, metaObject, data } = getTreeRows(rows);
		return {
			ids: idsArray,
			meta: metaObject,
			data: data,
		};
	}, [rows]);

	useEffect(() => {
		const expandedRowsArray = rows
			.filter((customer) => customer.parent)
			.map((customer) => customer.id);
		setExpandedRows(new Set(expandedRowsArray));
	}, [rows]);

	const { availableOrgTagsByName } = orgTagsContext;
	const columns = useMemo(
		() =>
			getColumns(
				intl,
				!!topDownUserManagement,
				!!enableUpBranchCode,
				!!enableCustomerDomain,
				availableOrgTagsByName,
			) as Column<CustomerDto>[],
		[
			availableOrgTagsByName,
			enableUpBranchCode,
			enableCustomerDomain,
			intl,
			topDownUserManagement,
		],
	);
	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	return (
		<Grid
			columns={columns}
			rows={treeData}
			actionsMenu={actionsMenu}
			filter={rowFilter}
			loading={loading}
			selectionMode="none"
			preferencesAdapter={preferencesAdapter}
			expandedRows={expandedRows}
			onExpandedRowsChange={setExpandedRows}
			defaultSort={[
				{
					columnId: 'title',
					direction: 'asc',
				},
			]}
			multiColumnSort={false}
			emptyContent={
				searchValue ? (
					<EmptyStateFilter onClearFilters={onClearFilters} />
				) : (
					<EmptyState
						header="No customers"
						description="There are no customers."
					/>
				)
			}
		/>
	);
};

export default OrganizationsGrid;
